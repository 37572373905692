.privacy {
    color: rgb(126, 154, 182);
    font-size: 12px;
}
.privacy-link {
    color: rgb(89, 164, 248);
    border-bottom: 1px solid rgb(89, 164, 248);
}
.jump-wrap {
    position: fixed;
    top: 52px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 'calc(100% - 88px)';
    overflow-y: auto;
    background-color: #fefefe;
    z-index: 9999;
    color: #333;
    padding-bottom: 36px;
}
.fix-wrap {
    top: 0;
    height: 'calc(100% - 36px)';
}
.privacy-title {
    text-align: center;
}
.terms-conditions-main {
    padding: 0 16px;
}
.close-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 16px;
    right: 16px
}
.terms-conditions-content {
    margin: 0;
    white-space: pre-wrap;
}
.terms-conditions-table {
    border: 1px solid #ccc;
    border-bottom-width: 0;
    margin-top: 8px;
    font-size: 12px;
    color: rgba(38, 105, 164);
}
.terms-conditions-table-row {
    border-bottom: 1px solid #ccc;
}
.terms-conditions-table-wrap {
    display: flex;
    flex-direction: row;
}
.row-title {
    width: 80px;
    border-right: 1px solid #ccc;
    padding: 4px 0 0 4px;
    font-weight: 700;
}
.row-s-title {
    width: 18px;
    border-right: 1px solid #ccc;
    padding: 4px 0 0 4px;
}
.row-content {
    flex: 1;
    padding: 4px;
}
.terms-conditions-list {
    white-space: pre-wrap;
    margin-bottom: 4px;
}
.terms-conditions-list-order {
    color: rgb(38, 105, 164);
}
.terms-conditions-list-indent {
    padding-left: 16px;
}